<template>
  <div>
    <div class="air__utils__heading">
      <h5>Reportes Logística</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--    Enlaces-->
    <div class="row">

      <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 offset-xl-4 offset-lg-3 offset-md-3 offset-sm-0">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'weighingReport'}">
                <strong><b-icon icon="truck"/></strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'weighingReport'}">
              <h6 class="text-block text-secondary">Reporte de Requerimiento Logístico</h6>
            </router-link>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-2 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-4 mb-2 text-center">
              <router-link :to="{ name: ''}">
                <b-iconstack font-scale="3" variant="primary">
                  <b-icon stacked icon="box-seam" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: ''}">
              <h5 class="text-block text-secondary">Reporte 2</h5>
            </router-link>
          </div>
        </div>
      </div> -->

    </div>
    <!--    Enlaces-->

  </div>
</template>

<script>

export default {
  name: '',
  components: {

  },

  computed: {
    // ...mapState(['servicePlannings']),
  },
  data() {
    return {
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'reports',
          breadcrumbName: 'Reportes',
          bIcon: '',
        },
        {
          breadcrumbName: 'Reportes Logística',
          aIcon: '',
        },
      ],
      resource: '',
      resourceName: 'Servicios',
      searchInput: null,
    }
  },
  methods: {

  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}
</style>
